import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { animations } from '../../styles/base/_allBase.styles.js';
import { CustomButtonContainer, CustomButtonTree } from './button.styles';

const CustomButton = ({ children, href = '/', ...props }) => (
	<Link href={href}>
		<CustomButtonContainer {...animations.motionHover} {...props}>
			{children}
		</CustomButtonContainer>
	</Link>
);

const ButtonNoLink = ({ children, ...props }) => (
	<CustomButtonContainer {...animations.motionHover} {...props}>
		{children}
	</CustomButtonContainer>
);

const ButtonNoLinkTree = ({ children, ...props }) => (
	<CustomButtonTree {...animations.motionHover} {...props}>
		{children}
	</CustomButtonTree>
);

CustomButton.propTypes = {
	children: PropTypes.string,
};

export { CustomButton, ButtonNoLink, ButtonNoLinkTree };
