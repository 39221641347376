import React, { useState, useEffect, useRef } from 'react';
import { Container, Cursor, ButtonDiv, LinkContainer } from './carousel.full-page.styles';
import { useCursorPosition, useCursorChange, useWrap, useCarousel } from '../../lib/hooks';
import { Children } from 'react';
import { styled, AnimatePresence, motion, Img, colors, breakpoints, typo, useCycle } from '../../styles/base/_allBase.styles';
// Components
import { CustomButton } from '../button/button.component';
// Animations
import { cursorDirectionAnimation } from '../../styles/base/_animations.styles';

const Carousel = ({ children, timeOut = 0, side, ...props }) => {
	const { cursorYSpring, cursorXSpring } = useCursorPosition();
	const [size, setSize, visible, setVisible, rotate, rotation] = useCursorChange();
	const [imageIndex, showHide, changeImage] = useCarousel(children, 0);
	const childArray = React.Children.toArray(children);

	const timeoutRef = useRef(null);
	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}
	useEffect(() => {
		resetTimeout();
		if (childArray[0].props.cycle) {
			timeoutRef.current = setTimeout(() => changeImage(1), 10000);
		} else {
			return;
		}
		return () => {
			resetTimeout();
		};
	}, [imageIndex]);

	return (
		<Container name={'carousel container'} onMouseEnter={setVisible} onMouseLeave={setVisible}>
			<AnimatePresence initial={false} exitBeforeEnter>
				{showHide && <motion.div key={imageIndex}>{React.cloneElement(childArray[imageIndex], { ...props })}</motion.div>}
			</AnimatePresence>
			<Cursor
				visible={visible.toString()}
				animate={rotate && visible ? 'rotate' : !size && visible ? 'small' : 'exit'}
				variants={cursorDirectionAnimation}
				style={{
					translateX: cursorXSpring,
					translateY: cursorYSpring,
				}}
			/>
			<ButtonDiv left={true.toString()} onClick={() => changeImage('prev')}></ButtonDiv>
			<ButtonDiv onMouseEnter={rotation} onMouseLeave={rotation} right={true.toString()} onClick={() => changeImage('next')}></ButtonDiv>
			{props.linkbutton && showHide && (
				<LinkContainer side={side}>
					<CustomButton onMouseEnter={setVisible} onMouseLeave={setVisible} inverted href={childArray[imageIndex].props.button.slug}>
						{childArray[imageIndex].props.button.label}
					</CustomButton>
				</LinkContainer>
			)}
		</Container>
	);
};

export { Carousel };
