import React from 'react';
import { useParallaxInView } from '../../lib/hooks';
import { Section } from '../section/section.component';
import { base, containers, useScroll, useSpring, useInView, animations } from '../../styles/base/_allBase.styles';
import Content from '../block-content/block-content';

const { H3, StyleP } = base;
const { TwoColumnGrid, GridItem, SectionHeaderContainer } = containers;

function HeaderTwoColumGrid({ data, header, text, textTwo }) {
	const { y, ref, isInView } = useParallaxInView(100);

	return (
		<Section style={{ zIndex: 1 }} ref={ref} cursorsize={'small'} size={'auto'}>
			<SectionHeaderContainer>
				<H3 style={{ y }} color='primary'>
					{data.header}
				</H3>
			</SectionHeaderContainer>
			<TwoColumnGrid ref={ref} style={{ y }}>
				<GridItem position={'left'}>
					<StyleP color='primary'>
						<Content blocks={data.text[0].text} />
					</StyleP>
				</GridItem>
				<GridItem position={'right'}>
					<StyleP color='primary'>
						<Content blocks={data.text[1].text} />
					</StyleP>
				</GridItem>
			</TwoColumnGrid>
		</Section>
	);
}

export { HeaderTwoColumGrid };
